define("discourse/plugins/discourse-plugin-vatprc/discourse/initializers/vatprc-tailwind", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "vatprc-tailwind",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", () => {
        const theme = getComputedStyle(document.documentElement).getPropertyValue("--scheme-type");
        document.querySelector("#main").classList.add(`vatprc-${theme}`);
        document.querySelector("#main").classList.remove(`vatprc-${theme === "dark" ? "light" : "dark"}`);
      });
    }
  };
});