define("discourse/plugins/discourse-plugin-vatprc/discourse/initializers/vatprc-fpl", ["exports", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/plugins/discourse-plugin-vatprc/discourse/components/fpl-builder"], function (_exports, _pluginApi, _utilities, _fplBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FlightPlan = _ref => {
    let {
      dep,
      arr,
      route,
      caption
    } = _ref;
    dep = dep.toUpperCase().trim();
    arr = arr.toUpperCase().trim();
    route = route.toUpperCase().trim();
    const title = caption ? caption : `${dep} - ${arr}`;
    const fullRouteEnc = encodeURIComponent(`${dep} ${route} ${arr}`);
    const routeEnc = encodeURIComponent(route);
    const [copied, setCopied] = htmPreact.useState(false);
    const copyFpl = htmPreact.useCallback(() => {
      (0, _utilities.clipboardCopy)(`${dep} ${route} ${arr}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
    return htmPreact.html`<div class="flex flex-col font-sans gap-2 !border-2 border-gray-300 p-2 rounded-md shadow-md my-2 dark:text-gray-100">
    <p class="font-mono flex flex-row items-center">
      <span class="text-xl font-sans font-bold mr-1 pr-2 border-r-2 border-gray-500">${title}</span>
      <span class="font-bold">${dep}</span>
      <span class="ml-2">${route}</span>
      <span class="font-bold ml-2">${arr}</span>
    </p>
    <div class="flex flex-row gap-1">
      <button class="fpl-button ${copied ? "text-green-700 dark:text-green-100" : ""}" title="Copy Route" type="button" onclick=${copyFpl}>
        <svg class="w-4 h-4 fill-current">
          <use href="#${copied ? "check" : "copy"}"></use>
        </svg>
      </button>
      <a class="fpl-button cursor-pointer" href="https://my.vatsim.net/pilots/flightplan?route=${routeEnc}&departure=${dep}&arrival=${arr}" target="_blank">
        <span>VATSIM Prefile</span>
      </a>
      <a class="fpl-button cursor-pointer" href="https://skyvector.com/?fpl=${fullRouteEnc}" target="_blank">
        <span>SkyVector</span>
      </a>
      <a class="fpl-button cursor-pointer" href="https://www.simbrief.com/system/dispatch.php?orig=${dep}&dest=${arr}&route=${routeEnc}" target="_blank">
        <span>SimBrief</span>
      </a>
    </div>
</div>`;
  };
  var _default = _exports.default = {
    name: "discourse-fpl",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        api.decorateCookedElement((cooked, postWidget) => {
          if (!postWidget) {
            return;
          }
          const fplNodes = cooked.querySelectorAll(".d-wrap[data-wrap=fpl]");
          fplNodes.forEach(( /** @type DOMDivElement */elem) => {
            let {
              dep,
              arr,
              title
            } = elem.dataset;
            let route = elem.innerText;
            if (!dep || !arr || !route) {
              return;
            }
            const div = document.createElement("div");
            div.className = "vatprc";
            elem.insertAdjacentElement("afterend", div);
            htmPreact.render(htmPreact.html`<${FlightPlan} dep=${dep} arr=${arr} route=${route} caption=${title} />`, div);
            elem.remove();
          });
          Array.from(cooked.querySelectorAll("h1,h2,h3,h4,h5,h6")).forEach(elem => {
            if (!elem.innerText.includes("航路")) {
              return;
            }
            const list = elem.nextElementSibling;
            if (!list) {
              return;
            }
            const airports = list.querySelectorAll("li");
            if (!airports) {
              return;
            }
            airports.forEach(airport => {
              const title = airport.querySelector("strong");
              if (!title) {
                return;
              }
              const depArr = /(\w{4})(?:\(P\))?\s*(?:-|→|->)\s*(\w{4})(?:\(P\))?/i.exec(title.innerText);
              if (!depArr || !depArr[1] || !depArr[2]) {
                return;
              }
              const [, dep, arr] = depArr;
              const routeElem = airport.querySelector("ul > li");
              if (!routeElem) {
                return;
              }
              const route = routeElem.innerText;
              const div = document.createElement("div");
              div.className = "vatprc";
              airport.insertAdjacentElement("afterend", div);
              htmPreact.render(htmPreact.html`<${FlightPlan} dep=${dep} arr=${arr} route=${route} caption=${title.innerText} />`, div);
            });
          });
        }, {
          onlyStream: true,
          id: "discourse-fpl-theme-component"
        });
        api.addComposerToolbarPopupMenuOption({
          label: "plugin_vatprc.fpl.toolbar.builder",
          icon: "file",
          action: toolbarEvent => {
            const modal = container.lookup("service:modal");
            modal.show(_fplBuilder.default, {
              model: {
                toolbarEvent
              }
            });
          }
        });
      });
    }
  };
});